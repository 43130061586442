import { useGameLikeWorkspace } from '../../../src/components/Game/GameCenter';
import { DefaultGamePackCardBadges } from '../../../src/components/Game/GamePack/GamePackCard';
import { GamePackCardBottomAccessory } from '../../../src/pages/GamePack/GamePackCardBottomAccessory';
import { type GamePack } from '../../../src/types/game';
import { GamePackCard } from '../GamePack/GamePackCollection';

export function AdminGamePackCard(props: { pack: GamePack }) {
  const { pack } = props;

  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');

  return (
    <GamePackCard
      gamePack={pack}
      onClick={() => setActiveGamePack(pack)}
      badges={<DefaultGamePackCardBadges gamePack={pack} />}
      bottomAccessory={<GamePackCardBottomAccessory pack={pack} />}
      styles={{
        size: 'w-full',
        badgesTranslateClassName: '-translate-y-1/4',
      }}
      showVersion={true}
    />
  );
}
